var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-8 text-xs"},[_c('div',{staticClass:"rounded-2xl bg-white p-8"},[_c('div',{},[_vm._m(0),_c('div',{staticClass:"mt-4 grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.postData()}}},[_c('ValidationProvider',{staticClass:"grid grid-cols-12 gap-4 items-center",attrs:{"rules":"required","name":"Nama","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("Nama*")]),_c('div',{staticClass:"col-span-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.name),expression:"addData.name"}],ref:"name",class:("block border border-gray-200 w-full p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"name","id":"name"},domProps:{"value":(_vm.addData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addData, "name", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"grid grid-cols-12 gap-4 items-center mt-4",attrs:{"rules":"required","name":"param","vid":"param"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("param*")]),_c('div',{staticClass:"col-span-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.param),expression:"addData.param"}],ref:"param",class:("block border border-gray-200 w-full p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"param","id":"param"},domProps:{"value":(_vm.addData.param)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addData, "param", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"grid grid-cols-12 gap-4 items-center mt-4",attrs:{"rules":"required","name":"satuan","vid":"satuan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-2",attrs:{"for":""}},[_vm._v("satuan*")]),_c('div',{staticClass:"col-span-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.satuan),expression:"addData.satuan"}],ref:"satuan",class:("block border border-gray-200 w-full p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"satuan","id":"satuan"},domProps:{"value":(_vm.addData.satuan)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addData, "satuan", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"float-right"},[_c('button',{class:"text-center py-2 px-8 rounded\n                            \n                            border border-gray\n                            mr-2\n                            hover:bg-gray-100\n                            disabled:opacity-50\n                            mt-4\n                            \n                            focus:outline-none my-1",on:{"click":function($event){return _vm.$router.push('/device-param')}}},[_vm._v("Batal")]),_c('button',{class:"text-center py-2 px-8 rounded\n                            text-white\n                            hover:bg-blue-900 bg-blue-500\n                            disabled:opacity-50\n                            mt-4\n                            \n                            focus:outline-none my-1",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v("Simpan")])])],1)]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('span',{staticClass:"font-semibold text-base"},[_vm._v("Ubah Device Param")])])}]

export { render, staticRenderFns }